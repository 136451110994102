import { Component, Vue, Ref } from "vue-property-decorator";
import debounce from "lodash.debounce";
import { numberToFixed } from "@helpers";

@Component({
  components: {
    Navbar: () => import("./components/Navbar/Navbar.vue"),
    Cookies: () => import("./components/Cookies/Cookies.vue"),
  },
})
export default class HomeLayout extends Vue {
  @Ref("cardFirst") private readonly cardFirstRef!: HTMLImageElement;
  @Ref("cardSecond") private readonly cardSecondRef!: HTMLImageElement;

  private initCardAnimate() {
    if (this.$vuetify.breakpoint.width < 1000) return;

    const onMousemove = debounce(
      (event: MouseEvent) => {
        const factorX = numberToFixed(
          (event.clientX - window.innerWidth / 2) / window.innerWidth,
          2
        );
        const factorY = numberToFixed(
          (event.clientY - window.innerHeight / 2) / window.innerHeight,
          2
        );

        this.cardFirstRef.style.setProperty(
          "--offset-x",
          `${numberToFixed(factorX * -100, 2)}px`
        );
        this.cardFirstRef.style.setProperty(
          "--offset-y",
          `${numberToFixed(factorY * 200, 2)}px`
        );

        this.cardSecondRef.style.setProperty(
          "--offset-x",
          `${numberToFixed(factorX * 200, 2)}px`
        );
        this.cardSecondRef.style.setProperty(
          "--offset-y",
          `${numberToFixed(factorY * -100, 2)}px`
        );
      },
      50,
      {
        maxWait: 50,
      }
    );

    window.addEventListener("mousemove", onMousemove);

    this.$once("hook:beforeDestroy", () => {
      onMousemove.cancel();
      window.removeEventListener("mousemove", onMousemove);
    });
  }

  private mounted() {
    this.initCardAnimate();
  }
}
